<template>
    <div class="CurriculumTypeList">
        <div class="pos-ab" id="curriculumListPos">
            <div class="nav-container">
                <div class="title">
                    {{ isOff ? "线下课程" : "课程分类" }}
                </div>
                <el-divider></el-divider>

                <div class="class pb-34">
                    <div class="class-cur">
                        <div class="typeList wrap" v-show="!isOff">
                            <div
                                style="font-weight: 600"
                                class="tag click"
                                :class="{ 'sel-tag': oneActive == -1 }"
                                @click="switchOneType(-1)"
                            >
                                全部
                            </div>
                            <div
                                class="tag click"
                                :class="{ 'sel-tag': oneActive == index }"
                                v-for="(item, index) in typeList"
                                :key="'tag' + index"
                                @click="switchOneType(index)"
                            >
                                <div>{{ item.name }}</div>
                            </div>
                        </div>
                        <div class="">
                            <div
                                class="sonType wrap"
                                v-for="(item, index) in typeList"
                                :key="'sonType' + index"
                                v-show="!isOff"
                            >
                                <div
                                    v-for="(sec, idx) in item.second"
                                    :key="'sonTag' + idx"
                                    class="flex sonTag"
                                    v-show="index == oneActive"
                                >
                                    <div
                                        class="ml-12 mr-12 flex-alc"
                                        v-if="idx != 0"
                                    >
                                        /
                                    </div>
                                    <div
                                        class="son-tag click"
                                        :class="{
                                            'sel-son-tag': twoActive == idx,
                                        }"
                                        @click="switchTwoType(idx)"
                                    >
                                        {{ sec.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="offLine click"
                        :class="{ 'back-red': isOff }"
                        @click="switchCurStatus"
                    >
                        <div class="offButton">
                            {{ isOff ? "线上" : "线下" }}课程
                        </div>
                        <img
                            src="@/assets/ic_ygxxsc@2x.png"
                            class="yjtImg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <div id="seat"></div>
        <div class="flex-alc wrap mt-59">
            <Curriculum
                v-for="(item, index) in curriculumList"
                :key="'cur' + index"
                class="mb-20"
                :curriculum="item"
                :imgSize="218"
                :border="6"
                :isOff="isOff"
                :class="{ 'mr-27': (index + 1) % 5 != 0 }"
            ></Curriculum>
            <NoData v-if="!curriculumList.length" text="暂无学习课程"></NoData>
        </div>
        <div class="flex-jsc mt-45 mb-63">
            <Pagination
                :pageSize="20"
                :total="total"
                :page="page"
                @getPage="getPage"
            ></Pagination>
        </div>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    import Curriculum from "@/components/Curriculum.vue";
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "CurriculumTypeList",
        components: {
            Curriculum,
            Pagination,
            NoData,
        },
        data() {
            return {
                oneActive: -1, //一级分类下标
                twoActive: 0, //二级分类下标
                typeList: [], //分类列表
                curriculumList: [], //课程列表
                page: 1, //当前页
                total: 0, //总数据数
                isOff: 0, //是否为线下课程
                httpUrl: ["/api/cur/list", "/api/user/offline/list"],
                page: 1,
            };
        },
        async mounted() {
            window.localStorage.setItem("navActive", 1);
            this.$store.commit("switchNav", 1);
            //获取分类列表
            await this.$https.get("/api/cur/category/list").then((res) => {
                this.typeList = res.data.list;
            });
            //获取课程列表
            this.isOff = +window.localStorage.getItem("isOff") || 0;
            await this.getCurData(1, 0, this.isOff);
            //占位容器
            this.$nextTick(() => {
                let posHeight = document.getElementById("curriculumListPos")
                    .offsetHeight;
                document.getElementById("seat").style.height = posHeight + "px";
            });
        },

        methods: {
            //改变课程状态 线上或线下
            async switchCurStatus() {
                await this.getCurData(1, 0, this.isOff ? 0 : 1);
                this.page = 1;
                this.isOff = this.isOff ? 0 : 1;
                window.localStorage.setItem("isOff", this.isOff);
                this.$nextTick(() => {
                    let posHeight = document.getElementById("curriculumListPos")
                        .offsetHeight;
                    document.getElementById("seat").style.height = posHeight + "px";
                });
            },
            //获取当前页
            getPage(e) {
                this.getCurData(e);
                this.page = e;
            },
            //获取课程列表
            async getCurData(page = 1, type = 0, isOff = 0) {
                await this.$https
                    .get(this.httpUrl[isOff], {
                        page,
                        limit: 20,
                        category:
                            this.oneActive == -1
                                ? ""
                                : this.typeList[this.oneActive].id,
                        second:
                            this.oneActive == -1
                                ? ""
                                : this.typeList[this.oneActive]?.second[
                                      this.twoActive
                                  ]?.id,
                    })
                    .then((res) => {
                        if (type) {
                            this.curriculumList = [
                                ...this.curriculumList,
                                ...res.data.list,
                            ];
                        } else {
                            this.curriculumList = res.data.list || [];
                        }

                        this.total = res.data.total_count;
                    });
            },
            //切换一级分类
            switchOneType(e) {
                this.oneActive = e;
                this.twoActive = e == -1 ? -1 : 0;
                this.page = 1;
                this.getCurData();
                this.$nextTick(() => {
                    let posHeight = document.getElementById("curriculumListPos")
                        .offsetHeight;
                    document.getElementById("seat").style.height = posHeight + "px";
                });
            },
            //切换二级分类
            switchTwoType(e) {
                this.twoActive = e;
                this.page;
                this.getCurData();
            },
        },
    };
</script>

<style lang="less" scoped>
.CurriculumTypeList {
    width: 1200px;
    margin: auto;
    .pos-ab {
        position: absolute;
        left: 0;
        width: 100%;
        background: white;
        .nav-container {
            width: 1200px;
            margin: auto;
            background: white;
            .title {
                font-size: 18px;
                font-weight: 600;
                color: #000000;
            }
            .el-divider--horizontal {
                margin: 9px 0 16px 0px;
            }
            .class {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .class-cur {
                    width: 83%;

                    .typeList {
                        display: flex;
                        .tag {
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                            margin-right: 38px;
                            padding: 8px 0;
                        }
                        .sel-tag {
                            color: #d30015;
                        }
                    }
                }
                .offLine {
                    background: #4477bc;
                    border-radius: 26px;
                    font-size: 18px;
                    font-weight: 600;
                    color: #ffffff;
                    padding: 13px 10px;
                    display: flex;
                    align-items: center;
                    .offButton {
                        width: 75px;
                    }
                    .yjtImg {
                        width: 19px;
                        height: 12px;
                        margin-left: 80px;
                    }
                }
                .back-red {
                    background: linear-gradient(
                        180deg,
                        #ff3764 0%,
                        #ff2604 100%
                    );
                }
            }
            .sonType {
                display: flex;
                .sonTag {
                    padding-top: 20px;
                    .son-tag {
                        font-size: 14px;
                        font-weight: 400;
                        color: #000000;
                        padding: 3px 8px;
                        border-radius: 13px;
                    }
                    .sel-son-tag {
                        background: #d30015;
                        color: white;
                    }
                }
            }
        }
    }
}
</style>